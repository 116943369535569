import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout>
      <SEO title="Accueil" />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}
            style={{
              marginBottom: `1em`,
            }}
          >
            <div style={{display: `flex`}}>
              <div style={{
                  marginRight: `1rem`,
                  minWidth: `150px`,
                }}>
                <div
                    style={{
                      marginTop: `.3em`,
                      fontSize: `.7em`,
                      textAlign: `right`,
                      color: `#999`,
                    }}
                  >{node.frontmatter.date}</div>
                <div
                  style={{
                    color: `#89a`,
                    textAlign: `right`,
                    padding: `0 .4em`,
                    fontSize: `.7em`,
                    borderTop: `1px dashed #ccc`,
                  }}
                >{node.frontmatter.category}</div>

              </div>
              <div>
                <h3
                  style={{
                    margin: `0`,
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>

                <section>
                  <p
                    style={{
                      margin: `0`,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </section>
              </div>
            </div>
          </article>
        )
      })}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { category: { ne: "page" }}},
      sort: { fields: frontmatter___date, order: DESC },
      limit: 1000
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D MMMM YYYY", locale: "fr")
            title
            description
            category
          }
        }
      }
    }
  }
`
